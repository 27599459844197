<template>
	<div class="main">
    <TheTopbar/>

    <button class="btn btn-secondary" @click="goBack()">back <b-badge class="text-dark" v-b-tooltip.hover title="Click to go back to the previous page">i</b-badge></button>
    <slot />
	<div class="main-container">
    <div class="d-flex">
      <div class="content-wrapper">
        <Header></Header>
            <div class="options">
                      
                      <button class="btn btn-main active" @click="goToProfile()" :disabled="!init.FXCUSTVIEW" v-b-tooltip.hover title="Click to view and edit the profile information of the authorized representative">AUTHORIZED REP</button>
                      <button class="btn btn-main " @click="goToCompany()" :disabled="!init.FXCUSTVIEW" v-b-tooltip.hover title="Click to view and edit the profile information of the company">COMPANY</button>
                      <button class="btn btn-main " @click="goToApplication()" :disabled="!init.FXCUSTAPPLICATION" v-b-tooltip.hover title="Click to view and action the application process of the customer">APPLICATION</button>
                      <!-- <button class="btn btn-main" @click="goToUsers()" :disabled="!init.FXCUSTUSERS">USERS</button> -->
                      <button class="btn btn-main" @click="goToTransactions()" :disabled="!init.FXCUSTTRANSACTIONS" v-b-tooltip.hover title="Click to view and monitor the transactions made by the customer">TRANSACTIONS</button>
                      <button class="btn btn-main" @click="goToActivityLogs()" v-b-tooltip.hover title="Click to view and monitor the activity logs of the customer">ACTIVITY LOGS</button>
                </div>
         

          <div class="market-content">
            <div v-if="errors.profile.show">
            <p style="color: pink;">{{errors.profile.msg}}</p>
          </div>
             <form>
                <button class="btn btn-secondary" @click.prevent="editGeneralProfile()" v-if="!isEditingProfile" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to edit or update the profile information.">Edit
                </button>
                 <div class="text-center" v-if="isEditingProfile"> 
                    <span >
                      <button class="btn btn-secondary" type="submit" @click.prevent="updateGeneralProfile()" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to save the changes made.">Save</button>
                    </span>
                    <span ><button class="btn btn-secondary" v-on:click="cancelEditProfile" :disabled="!init.FXCUSTEDIT" >Cancel</button></span>
                    
                  </div>
               
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                       Email Address:
                      </th>
                      <th>
                       Mobile Number:
                      </th>
                      <th>
                        Gender:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td v-if="!isEditingProfile">{{show.profile.emailAddress}}</td>
                    <td v-else><input class="form-control" v-model="show.profile.emailAddress"/></td>
                    <td v-if="!isEditingProfile">{{show.profile.mobilePhone}}</td>
                    <td v-else>
                      <div class="mobile-container">
                      <div class="input-group mb-3" >
                        <div class="input-group-prepend">
                          <button class="btn btn-flag" type="button" v-on:click="toggleCountryList">
                            <img class="default-flag" :src="require('@/assets/signup_flags/' + country + '.png' )">
                            <span class="text-dark">{{ mobileCode }}</span>
                          </button>
                        </div>
                        <input type="tel" class="form-control" v-model="mobileNumber" @keypress="isNumber($event)" >
                      </div>
                      <div class="country-list" v-if="showCountry">
                        <div class="row">
                          <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountry(l)" >
                            <div>
                              <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                            </div>
                            <div class="country-name">
                              <span> {{ l.country }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    </td>
                     <td v-if="!isEditingProfile">{{show.profile.gender}}</td>
                    <td v-else>
                      <select id="gender" v-model="show.profile.gender" class="form-control">
                        <option value="" disabled>Select</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                       
                      </select>
                    </td>
                  </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                       First name:
                      </th>
                      <th>
                       Middle name:
                      </th>
                      <th>
                       Last name:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                     <td v-if="!isEditingProfile">{{show.profile.firstName}}</td>
                    <td v-else><input class="form-control" v-model="show.profile.firstName"/></td>
                    <td v-if="!isEditingProfile">{{show.profile.middleName}}</td>
                     <td v-else><input class="form-control" v-model="show.profile.middleName"/></td>
                     <td v-if="!isEditingProfile">{{show.profile.lastName}}</td>
                     <td v-else><input class="form-control" v-model="show.profile.lastName"/></td>
                  </tbody>
               
                  <thead>
                    <tr>
                      <th>
                       Birth day:
                      </th>
                      <th>
                       Birth month:
                      </th>
                      <th>
                       Birth year:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td v-if="!isEditingProfile">{{show.profile.birthDd}}</td>
                    <td v-else>
                      <select v-model="show.profile.birthDd" @change="updateDate" class="form-control">
                        <option value="">DD</option>
                        <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                      </select>
                    </td>
                    <td v-if="!isEditingProfile">{{show.profile.birthMm}}</td>
                    <td v-else>
                      <select v-model="show.profile.birthMm" @change="updateDate" class="form-control">
                        <option value="">MM</option>
                        <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                      </select>
                    </td>
                    <td v-if="!isEditingProfile">{{show.profile.birthYear}}</td>
                    <td v-else>
                      <select v-model="show.profile.birthYear" @change="updateDate" class="form-control">
                        <option value="">YYYY</option>
                        <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                      </select>
                    </td>
                  </tbody>
                </table>
                 <table>
                  <thead>
                    <tr>
                      <th>
                       Address 1:
                      </th>
                      <th>
                       City:
                      </th>
                      <th>
                       Province:
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!isEditingProfile">
                    <td v-if="show.profile.presentAddress != null">{{show.profile.presentAddress.numberStreet}}</td>

                    <td v-if="show.profile.presentAddress != null">{{show.profile.presentAddress.cityTown}}</td>
                    <td v-if="show.profile.presentAddress != null">{{show.profile.presentAddress.provState}}</td>
                  </tbody>
                  <tbody v-else>
                     <td ><input class="form-control" v-model="profile.presentAddress.numberStreet"/></td>
                     <td  ><input class="form-control" v-model="profile.presentAddress.cityTown"/></td>
                     <td  ><input class="form-control" v-model="profile.presentAddress.provState"/></td>
                  </tbody>
                 </table>
                 <table class="table">
                  <thead>
                    <tr>
                      <th>
                       Country:
                      </th>
                      <th>
                      Postal Code:
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!isEditingProfile" >
                    <td v-if="show.profile.presentAddress != null">{{show.profile.presentAddress.country}}</td>
                    <td v-if="show.profile.presentAddress != null" >{{show.profile.presentAddress.postalCode}}</td>
                  </tbody>
                  <tbody v-else>
                    <td >
                       <div class=" mobile-container">
                        <div class="input-group mb-3">
                          <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="acountry" v-on:click="toggleCountryListForAddress" >
                        </div>
                        <div class="country-list" v-if="showCountryForAddress">
                          <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                              <div>
                                <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                              </div>
                              <div class="country-name">
                                <span> {{ l.country }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td ><input class="form-control" v-model="profile.presentAddress.postalCode"/></td>
                  </tbody>
                </table>
                </form>
              <hr>
              <br>



              <form>
                <div v-if="errors.profile.show">
                <p style="color: lightgray;">{{errors.profile.msg}}</p>
              </div>
                 <button class="btn btn-secondary" @click.prevent="editExtendedInfo()" v-if="!isEditingExt" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to edit or update the profile information.">Edit
                </button>
                 <div class="text-center" v-if="isEditingExt"> 
                    <span ><button class="btn btn-secondary" type="submit" @click.prevent="updateExtProfile()" v-b-tooltip.hover title="Click to save the changes made">Save</button></span>
                    <span ><button class="btn btn-secondary" v-on:click="cancelEditExt">Cancel</button></span>
                    
                  </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                       Marital Status:
                      </th>
                      <th>
                       Place of Birth:
                      </th>
                      <th>
                       Nationality:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td v-if="!isEditingExt">{{show.profile.maritalStatus}}</td>
                     <td v-else>
                      <select id="marital-status" v-model="show.profile.maritalStatus" class="form-control">
                        <option value="" disabled>Select Status</option>
                        <option value="S">Single</option>
                        <option value="M">Married</option>
                        <option value="D">Divorced</option>
                        <option value="W">Widowed</option>
                      </select>
                     </td>
                    <td v-if="!isEditingExt">{{show.profile.placeOfBirth}}</td>
                    <td v-else><input class="form-control" v-model="show.profile.placeOfBirth"/></td>
                    <td v-if="!isEditingExt">{{show.profile.nationality}}</td>
                    <td v-else>
                      <div class=" mobile-container">
                        <div class="input-group mb-3">
                        
                          <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="ncountry" v-on:click="toggleCountryListForNationality" >
                        </div>
                        <div class="country-list" v-if="showCountryForNationality">
                          <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForNationality(l)" >
                              <div>
                                <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                              </div>
                              <div class="country-name">
                                <span> {{ l.country }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tbody>
                </table>
                 <table>
                  <thead>
                    <tr>
                       <th>
                       ID number:
                      </th>
                      <th>
                       Mother's maiden name:
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody>
                    <td v-if="!isEditingExt">{{show.profile.identityNumber}}</td>
                    <td v-else><input class="form-control" v-model="show.profile.identityNumber"/></td>
                    <td v-if="!isEditingExt">{{show.profile.mothersMaidenName}}</td>
                    <td v-else><input class="form-control" v-model="show.profile.mothersMaidenName"/></td>
                    
                  </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                       Source of Funds:
                      </th>
                      <th>
                       Gross Monthly Income:
                      </th>
                      <th>
                       Position:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td v-if="!isEditingExt">{{show.profile.sourceOfFunds}}</td>
                    <td v-else>
                      <select id="marital-status" v-model="show.profile.sourceOfFunds" class="form-control">
                        <option value="" disabled>Select source of funds</option>
                        <option value="selfEmployed">self-employed</option>
                        <option value="salary">salary</option>
                        <option value="investment">investment</option>
                      </select>
                    </td>
                    <td v-if="!isEditingExt && show.profile.grossMonthlyIncome == '0'">Php0-50,000</td>
                    <td v-if="!isEditingExt && show.profile.grossMonthlyIncome == '50000'">Php0-50,000</td>
                    <td v-if="!isEditingExt && show.profile.grossMonthlyIncome == '100000'">Php50,001 - 100,000</td>
                    <td v-if="!isEditingExt && show.profile.grossMonthlyIncome == '500000'">Php100,001 - 500,000</td>
                    <td v-if="!isEditingExt && show.profile.grossMonthlyIncome == '1000000'">Php500,000 and above</td>
                     <td v-if="isEditingExt">
                      <select id="marital-status" v-model="show.profile.grossMonthlyIncome" class="form-control">
                        <option value="" disabled>Select</option>
                        <option value="50000">Php0 - 50,000</option>
                        <option value="100000">Php50,001 - 100,000</option>
                        <option value="500000">Php100,001 - 500,000</option>
                        <option value="1000000">Php500,000 and above</option>
                      </select>
                    </td>
                    <td v-if="!isEditingExt">{{show.profile.occupation}}</td>
                    <td v-else><input class="form-control" v-model="show.profile.occupation"/></td>
                  </tbody>
                </table>
              </form>
              </div>
              <div class="market-content"> 
               <table class="table"> 
             
               
               </table>
              </div>
              <div> 
                <button class="btn btn-secondary" @click="uploadId()" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to upload ID on behalf of the customer">upload ID</button>
              </div>
              <div class="market-content"> 
                <table class="table">
                  <thead>
                    <tr >
                        <th>ID Type</th>
                        <th>Upload Date</th>
                        <th>Status Date</th>
                        <th>Status</th>
                        <th>Reviewed by</th>
                        <th>Remarks</th>
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in authorizedRepOnly" :key="item.id">
                        <td>{{ item.poidType }}</td>
                        <td>{{ formatDate(item.uploadDate )}}</td>
                        <td>{{ formatDate(item.statusDate) }}</td>
                        <td>{{ item.approvalStatus }}</td>
                        <td>{{ item.reviewedBy }}</td>
                        <td>{{ item.comments }}</td>
                        <td><button class="btn btn-primary" @click="viewPoidFile(item)" v-b-tooltip.hover title="Click to view, download and action the uploaded document">view</button></td>
                    </tr>
                  </tbody>
                  <!-- <tbody>
                  <tr v-if="verificationOfInfo.length == 0">
                    <td colspan="6">VERIFICATION OF INFORMATION not UPLOADED YET</td>
                  </tr>
                  <tr v-if="verificationOfInfo.length > 0" v-for="item in verificationOfInfo" :key="item.id">
                    <td>{{ item.description }}</td>
                    <td>{{ formatDate(item.createDate) }}</td>
                    <td v-if="item.updateDate != null">{{ formatDate(item.updateDate) }}</td>
                    <td v-if="item.updateDate == null">-</td>
                    <td>{{ item.status }}</td>
                    <td v-if="item.processedBy != null">{{ item.processedBy }}</td>
                    <td v-if="item.processedBy == null">-</td>
                    <td><button class="btn btn-primary" @click="viewUpload(item)">view</button></td>
                  </tr>
                </tbody> -->
                </table>
              </div>
              </div>
              <ViewUploadPoid v-if="viewFile" @close="viewPoidFile(item)" :data="poidData" :format="item.fileType"></ViewUploadPoid>
              <UploadId v-if="showUploadId" @close="uploadId()" :member="member"/>
              <ViewUpload v-if="showUpload" @close="viewUpload(dataInfo)" :data="dataInfo" :format="item.fileType"></ViewUpload>
        </div>
         </div>
	</div>
</template>
        
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Approve from '../components/modal/Approve.vue'
  import Header from '../components/Header.vue'
  import UploadId from '../components/modal/Documents/UploadId.vue'
  import ViewUploadPoid from '../components/modal/Documents/ViewUploadPoid.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'

  export default {
      name: 'CustomerProfile',
      components: {
        TheTopbar,
        Approve,
        Header,
        UploadId,
        ViewUploadPoid,
        ViewUpload
         
      },
      
       data: function() {
      return { 
        showChangePin: false,
        showLogoutModal: false,
        showInputComment: false,
        selected: '',
          state: 1,
          birthdate:'',
          birthDate:{
            day: null,
            month: null,
            year: null
          },
          days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
          isMobile: false,
          birthdate: '',
          formattedBirthdate: '',
          isUnderage: false,
          month:'',
          day:'',
          year:'',
          errorMsg:'',
          
          showCountryForNationality: false,
          showCountryForAddress: false,
          ncountry:'PH',
          acountry:'PH',
          profile:{
              presentAddress:{
                numberStreet:'',
                cityTown:'',
                provState:'',
                country:'',
                postalCode:''
              },
              occupation:"",
              maidenName:"",
              natureOfWork:"",
              sourceOfFunds:"",
              selectedMaritalStatus:"",
              employer:"",
              idNumber:"",
              homePhone: '11111',
              grossMonthly: '',
              nationality:'PH'
          },
          errors: {
          profile: {
              msg: "",
              show: false
          }
          },
          showCountry: false,
          selected: 'mobile',
          country: 'PH',
          mobileCode: '63',
          mobileNumber:'',
          showCountryForMobile: false,
          hideOrShow: "Show",
          list: {
              mobileCodes: []
          },
          isError: false,
          showUpload: false,
          showExitModal: false,
          isEditingExt: false,
          isEditingProfile: false,
          isSubmitting: false,
          
          poid:[],
          poidItem:'',
          showDetails: false,
          poidData:'',
          poidKey:'',
          viewFile: false,
          showUploadId: false,
          showUpload: false,
          poidDesc: 'VALID ID',
          item:{
            fileType:''
          },
          
              FXAPPROVERONE: false,
              FXAPPROVERTWO: false,
              FXCOMPLIANCE: false,
              FXCOMPLIANCECHECKER: false,
              FXCOMPLIANCEMAKER: false,
              FXCUSTSEARCH: false,
              FXDOCUMENTS: false,
              FXNAMESCREEN: false,
              FXTRANSACTION: false,
              FXCUSTVIEW: false,
              FXCUSTEDIT: false,
      
      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        authorizedRepOnly(){
          return this.poid.filter(item => item.poidDesc === this.poidDesc)
        },
        verificationOfInfo(){
          return this.init.list.documentData.filter(item => item.fileType === 'VERIFICATION OF INFORMATION')
        }
       

      },
      methods:{
        viewUpload(docData){
          this.dataInfo = docData
          this.showUpload = !this.showUpload
        },
        goHome(){
          window.location.href="/#/customer-search";
        },
        goBack(){
          this.$router.go(-1);
        },
        goToActivityLogs(){
          window.location.href="/#/customer-activity/" + this.$route.params.entityId
        },
        goToCompany(){
          window.location.href="/#/customer-company/" + this.$route.params.entityId
          },
        goToApplication(){
          console.log(this.show.profile.signupHost)
          if(this.show.profile.signupHost == 'fintech.ceza.org'){
            window.location.href="/#/customer-application-list/" + this.$route.params.entityId
          }
          if(this.show.profile.signupHost == 'gaming.ceza.org'){
            window.location.href="/#/customer-gaming-list/" + this.$route.params.entityId
          }
         
        },
        goToUsers(){
          window.location.href="/#/customer-users/" + this.$route.params.entityId
        },
        goToTransactions(){
          window.location.href="/#/customer-transactions/" + this.$route.params.entityId
        },
        goToProfile(){
          window.location.href="/#/customer-profile/" + this.$route.params.entityId
        },
        uploadId(member){
          this.member = member
          this.showUploadId = !this.showUploadId
        },
        viewPoidFile(doc){
          this.poidData = doc
          this.viewFile = !this.viewFile
        },
        updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
       editGeneralProfile(){
          if(!this.isEditingProfile) {
            this.isEditingProfile = true;
          } else {
          this.isSubmitting = true;
          }
       },
       editExtendedInfo(){
         if(!this.isEditingExt) {
            this.isEditingExt = true;
          } else {
          this.isSubmitting = true;
          }
       },
       toggleCountryList: function(){
              this.showCountry = !this.showCountry;
              
              },
          
        selectCountry: function(c) {
              this.showCountry = false;
              this.country = c.countryCode;
              this.mobileCode = c.mobileCode;
          },
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
        toggleCountryListforAccountAddress(){
        this.showCountryForAccountAddress = !this.showCountryForAccountAddress;
      },
      selectCountryForAccountAddress: function(c) {
				this.showCountryForAccountAddress = false;
        this.business.account.address.country = c.country
        this.accountcountry = c.countryCode
			},
        toggleCountryListForNationality(){
            this.showCountryForNationality = !this.showCountryForNationality;
          },
          selectCountryForNationality: function(c) {
            this.showCountryForNationality = false;
            this.ncountry = c.countryCode
           
          },
          toggleCountryListForMobile(){
            this.showCountryForMobile = !this.showCountryForMobile;
          },
          selectCountryForMobile: function(c) {
            this.showCountryForMobile = false;
            this.mobileCode = c.countryCode
            this.country = c.country
           
          },
          toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
       
         
          formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
        getUserId(){
			    let userId = localStorage.getItem("userId")
			 
          	const params = {
            userId: userId,

          }
          const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');

          const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/getPermissionsByUser',
					data
                    };

				this.axios(options)
				.then((response) => { 
                    console.log(response.data)
				
					response.data.forEach((value) => {
						
						if(value == "FXADDCUSTOMER"){
							this.FXADDCUSTOMER = true;
						}
						if(value == "FXADMIN"){
							this.FXADMIN= true;
						}
						if(value == "FXAPPROVERONE"){
							this.FXAPPROVERONE= true;
						}
						if(value == "FXAPPROVERTWO"){
							this.FXAPPROVERTWO= true;
						}
						if(value == "FXCOMPLIANCE"){
							this.FXCOMPLIANCE= true;
						}
						// if(value == "FXCOMPLIANCECHECKER"){
						// 	this.FXCOMPLIANCECHECKER= true;
						// }
						if(value == "FXCOMPLIANCEMAKER"){
							this.FXCOMPLIANCEMAKER= true;
						}
						if(value == "FXCREATECUSTOMER"){
							this.FXCREATECUSTOMER= true;
						}
            			if(value == "FXCUSTSEARCH"){
							this.FXCUSTSEARCH= true;
						}
						if(value == "FXDOCUMENTS"){
							this.FXDOCUMENTS= true;
						}
						if(value == "FXNAMESCREEN"){
							this.FXNAMESCREEN= true;
						}
						if(value == "FXPENDINGSIGNUPS"){
							this.FXPENDINGSIGNUPS= true;
						}
						if(value == "FXSUPERADMIN"){
							this.FXSUPERADMIN= true;
						}
						if(value == "FXTRANSACTION"){
							this.FXTRANSACTION= true;
						}
                        if(value == "FXCUSTVIEW"){
							this.FXCUSTVIEW= true;
						}

					})
            })
        },
        getBusinessFile() {
            const params = {
              entityId: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/customer/businessfile/' 
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                 this.init.list.documentData = response.data

                 
              }).catch((err) => {
                  
              })
          },
        getPoid() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerPoids',
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                 this.poid = response.data

                 let item = [];
                 this.poid.forEach((el, i) => {
                  item.push(el);
                  this.imgUrl = el.poidFile
                  });
                  this.item.fileType = this.imgUrl.split('.').pop();
                 
              }).catch((err) => {
                  
              })
          },
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            data,
              url: '/ax/getCustomerProfile',
              }
              this.axios(options)
              .then((response) => {
              this.show.profile = response.data;
              this.show.corpDetails = response.data.corpDetails
               
              if(response.data.presentAddress != null){
                this.show.profile.presentAddress.numberStreet = response.data.presentAddress.numberStreet
                this.show.profile.presentAddress.cityTown = response.data.presentAddress.cityTown
                this.show.profile.presentAddress.provState = response.data.presentAddress.provState
              }
              }).catch((err) => {
                  
              })
          },
          cancelEditProfile(){
              this.isEditingProfile = false;  
              window.location.reload();
          },
          cancelEditExt(){
              this.isEditingExt = false;  
              window.location.reload();
          },
          

          updateGeneralProfile(){
        
              const params = {
                  entityId: this.$route.params.entityId,
                  title: this.show.profile.title,
                  externalId: '-',
                  firstName: this.show.profile.firstName,
                  middleName: this.show.profile.middleName,
                  lastName: this.show.profile.lastName,
                  birthYear: this.show.profile.birthYear,
                  birthMm: this.show.profile.birthMm,
                  birthDd: this.show.profile.birthDd,
                  nationality: this.ncountry,
                  emailAddress: this.show.profile.emailAddress,
                  mobilePhone: this.mobileCode + this.mobileNumber,
                  "presentAddress.addressExtraInfo": '-',
                  homePhone: "1111",
                  "permanentAddress.addressExtraInfo": '-',
                  permanentAddrSameAsPresent: false,
                  gender: this.show.profile.gender
              }

                  if(this.show.profile.presentAddress == null){
                    params["presentAddress.numberStreet"] = this.profile.presentAddress.numberStreet;
                    params["presentAddress.cityTown"] = this.profile.presentAddress.cityTown;
                    params["presentAddress.postalCode"] = this.profile.presentAddress.postalCode;
                    params["presentAddress.provState"] = this.profile.presentAddress.provState;
                    params["presentAddress.country"] = this.acountry;
                    params["permanentAddress.numberStreet"] = this.profile.presentAddress.numberStreet;
                    params["permanentAddress.cityTown"] = this.profile.presentAddress.cityTown;
                    params["permanentAddress.postalCode"] = this.profile.presentAddress.postalCode;
                    params["permanentAddress.provState"] = this.profile.presentAddress.provState;
                    params["permanentAddress.country"] = this.acountry;
                  }
                  else{
                    params["presentAddress.numberStreet"]= this.show.profile.presentAddress.numberStreet,
                    params["presentAddress.cityTown"]= this.show.profile.presentAddress.cityTown,
                    params["presentAddress.postalCode"]= this.show.profile.presentAddress.postalCode,
                    params["presentAddress.provState"]= this.show.profile.presentAddress.provState,
                    params["presentAddress.country"]= this.show.profile.presentAddress.country,
                    params["permanentAddress.numberStreet"]= this.show.profile.presentAddress.numberStreet,
                    params["permanentAddress.cityTown"]= this.show.profile.presentAddress.cityTown,
                    params["permanentAddress.postalCode"]= this.show.profile.presentAddress.postalCode,
                    params["permanentAddress.provState"]= this.show.profile.presentAddress.provState
                    params["permanentAddress.country"]= this.show.profile.presentAddress.country
                  }
                 

              

                  const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/updateCustomerGeneralInfo'
                          };
                  this.axios(options)
                      .then((response) => { 
                        console.log(response.data)
                         
                          this.isSubmitting = false;
                          this.isEditingProfile = false; 
                          this.errors.profile.show = true;
                          this.errors.profile.msg = "Profile updated successfully";
                          setTimeout(()=>{
                            window.location.reload();
                            }, 4000)

                      }).catch((err) => { 
                        
                          this.isSubmitting = true;
                          this.isEditingProfile = true; 
                          this.errors.profile.show = true;
                          this.errors.profile.msg = err.response.data.msgText;

                          if(err.response.data.fieldErrors){
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                          }, 4000)
                        }
                        else {
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.msgText
                          }

                  })
              
          
          },
          updateExtProfile(){
            
              const params = {
                entityId: this.$route.params.entityId,
                  nationality: this.ncountry,
                  placeOfBirth: this.show.profile.placeOfBirth,
                  occupation: this.show.profile.occupation,
                  natureOfWork: this.show.profile.natureOfWork,
                  identityNumber: this.show.profile.identityNumber,
                  mothersMaidenName: this.show.profile.mothersMaidenName,
                  maritalStatus: this.show.profile.maritalStatus,
                  employer: this.show.profile.employer,
                  sourceOfFunds: this.show.profile.sourceOfFunds,
                  grossMonthlyIncome: this.show.profile.grossMonthlyIncome,
                  gender: this.show.profile.gender

              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/updateCustomerExtendedInfo'
                          };
                  this.axios(options)
                      .then((response) => { 
                        
                        // window.location.reload();
                          this.isSubmitting = false;
                          this.isEditingExt = false; 
                          this.errors.profile.show = true;
                          this.errors.profile.msg = "Profile updated successfully";
                          setTimeout(()=>{
                            window.location.reload();
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                            }, 2000)

                      }).catch((err) => { 
                          this.isSubmitting = true;
                          this.isEditingExt = true; 
                          if(err.response.data.fieldErrors){
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                            setTimeout(()=>{
                              window.location.reload();
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                          }, 4000)
                        }
                        else {
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.msgText
                          }

                     })
              
          
          },
         
          isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
              evt.preventDefault();
              } else {
              return true;
              }
          },
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getMobileCodes();
          this.getPoid();
          this.getUserId();
          this.getBusinessFile();
      }
  }
</script>
        
<style lang=scss scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
 .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    width: 100%;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
 
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>